import './ProgressRing.css'

import React from 'react'

const ProgressRing = props => {
  const { 
    progress = 1,
    color = '#B13D89'
  } = props

  const size = 66
  const center = size / 2
  const strokeWidth = 3
  const radius = (size / 2) - (strokeWidth * 2)
  const circumference = radius * 2 * Math.PI
  const strokeOffset = circumference - progress / 100 * circumference
  

	return (
    <div className="progress-ring__wrap">
      <svg
        className="progress-ring"
        width={66}
        height={66}
      >
        <circle
          className="progress-ring__circle"
          stroke={color}
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={center}
          cy={center}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeOffset}
        />
      </svg>
      <div className="progress-ring__num">{progress.toFixed(0)}%</div>
    </div>
	)
}

export default ProgressRing