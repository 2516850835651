import React, { Fragment, useEffect, useRef, useState } from 'react'

import Button from '../Button'
import { useMediaStreams } from '../MediaStreamRecorder'

import bulb from './bulb.svg'

const PreTestCamera = (props) => {
  const videoRef = useRef()
  const mediaStreams = useMediaStreams()
  const [hasStream, setHasStream] = useState(false)
  const [streamWidth, setStreamWidth] = useState(560)
  const [streamHeight, setStreamHeight] = useState(320)

  useEffect(() => {
    if (hasStream) return;
    
    async function connectVideo () {
      if (navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true })
          const {width, height} = stream.getTracks()[0].getSettings();
          videoRef.current.srcObject = stream
          mediaStreams.setup('camera', stream)
          setHasStream(true);
          if (width) setStreamWidth(width)
          if (height) setStreamHeight(height)
        } catch (err) {
          console.log("Something went wrong!", err)
        }
      }
    }

    connectVideo()
  })

  return (
    <Fragment>
    <div className="pretest-panel">
      <h2>Now let’s set up your camera.</h2>
      <div className="k-cam-wrap">
        <video className="k-cam" autoPlay ref={videoRef} width={streamWidth} height={streamHeight}/>
        {!hasStream &&
          <div className="k-cam-msg">
            <img src={bulb} alt=""/><br/>
            We need to use your webcam to record the test.<br/>
            Please click “Allow” in the box above.
          </div>
        }
      </div>
      <p>
        {hasStream && <Button hasArrow handleClick={props.next}>Next</Button>}
      </p>
    </div>
    </Fragment>
  )
}

export default PreTestCamera
