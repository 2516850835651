import './App.css'

import Amplify, { API, Auth, Storage } from 'aws-amplify'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef
} from 'react'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isChrome
} from "react-device-detect";

import { MediaStreamRecorder } from './components/MediaStreamRecorder'
import PostTest from './components/PostTest/'
import PreTest from './components/PreTest'
import ABTest from './components/ABTest'
import SingleAssetTest from './components/SingleAssetTest'
import MobileWarning from './components/MobileWarning'
import NotChrome from './components/NotChrome'
import ProcessingCover from './components/ProcessingCover'
import TestBlocked from './components/TestBlocked'

import GTM from './lib/gtm'

import { UserAgent } from "react-useragent";

const IdentityPools = {
  master: {
      "IdentityPoolId": "eu-west-1:448bca95-2852-441c-9821-46956fd8b42e",
      "IdentityPoolName": "dev_platform_identity_pool__master"
  },
  dev: {
      "IdentityPoolId": "eu-west-1:7d78f45c-255c-41ca-9551-eb37bece2209",
      "IdentityPoolName": "dev_platform_identity_pool__develop"
  },
  qat: {
      "IdentityPoolId": "eu-west-1:8f707a75-d191-4cc7-a34e-5cfc6c65602d",
      "IdentityPoolName": "dev_platform_identity_pool__qat"
  },
  beta: {
      "IdentityPoolId": "eu-west-1:dd59ebac-2f7c-46c6-ac3c-b76f471c09cb",
      "IdentityPoolName": "dev_platform_identity_pool__beta"
  },
  production: {
      "IdentityPoolId": "eu-west-1:448bca95-2852-441c-9821-46956fd8b42e",
      "IdentityPoolName": "dev_platform_identity_pool__master"
  },
};

const ApiPrefix = {
  master: 'n1exzht3zk',
  dev: 'fspxhrqs22',
  qat:'11rwe4de7l',
  beta: 'lfadx0nax5'
};

const s3Buckets = {
  master: 'participantstoragedev205747platform-master',//dev
  dev: 'participantstoragedev205747205309-develop',
  qat:'participantstoragedev205747platform-qat',
  beta: 'participantstoragedev205747platform-beta'
};

let kanjo__aws_api_endpoint = 'https://fspxhrqs22.execute-api.eu-west-1.amazonaws.com/develop';
let kanjo__aws_graphql_endpoint = 'https://dh73irqisbczbimzdl6lfzgyly.appsync-api.eu-west-1.amazonaws.com/graphql';
let kanjo__aws_user_files_s3_bucket = 'participantstoragedev205747205309-develop';
let aws_cognito_identity_pool_id = IdentityPools.dev.IdentityPoolId;

if((typeof process.env.REACT_APP_BRANCH !== 'undefined') && process.env.REACT_APP_BRANCH !== 'dev'){
  kanjo__aws_api_endpoint = 'https://'+ApiPrefix[process.env.REACT_APP_BRANCH]+'.execute-api.eu-west-1.amazonaws.com/'+process.env.REACT_APP_BRANCH;
  //kanjo__aws_user_files_s3_bucket = 'participantstoragedev205747205309-'+process.env.REACT_APP_BRANCH;
  if(typeof IdentityPools[process.env.REACT_APP_BRANCH] !== 'undefined'){
    aws_cognito_identity_pool_id = IdentityPools[process.env.REACT_APP_BRANCH].IdentityPoolId;
  }
  if(typeof s3Buckets[process.env.REACT_APP_BRANCH] !== 'undefined'){
    kanjo__aws_user_files_s3_bucket = s3Buckets[process.env.REACT_APP_BRANCH];
  }
}

if(typeof process.env.REACT_APP_AWS_API_ENDPOINT !== 'undefined'){
  kanjo__aws_api_endpoint = process.env.REACT_APP_AWS_API_ENDPOINT;
}

if(typeof process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET !== 'undefined'){
  kanjo__aws_user_files_s3_bucket = process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET;
}



// TODO: "develop" should change based on build target
const awsmobile = {
  "aws_project_region": "eu-west-1",
  "aws_cognito_identity_pool_id": aws_cognito_identity_pool_id,
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_XuDmGyWi1",
  "aws_user_pools_web_client_id": "22cs5ccn2cu50dvud73enart1l",
  "oauth": {},
  "aws_cloud_logic_custom": [
      {
          "name": "REST",
          "endpoint": kanjo__aws_api_endpoint,
          "region": "eu-west-1"
      }
  ],
  "aws_appsync_graphqlEndpoint": kanjo__aws_graphql_endpoint,
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_user_files_s3_bucket": kanjo__aws_user_files_s3_bucket,
  "aws_user_files_s3_bucket_region": "eu-west-1"
};

//console.log('awsmobile', awsmobile);

Amplify.configure(awsmobile);

function App (props, ref) {
	
  
	
  const [currentCredentials, setCurrentCredentials] = useState(null);
  const [stored, setStored] = useState(null);
  const [postTestOpen, setPostTestOpen] = useState(false)
  const [preTestOpen, setPreTestOpen] = useState(false)
  const [testRunning, setTestRunning] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [assetReady, setAssetReady] = useState(false)
  const [assetLoadedPercent, setAssetLoadedPercent] = useState(0)
  const [testSpec, setSpec] = useState(null)
  const [processing, setProcessing] = useState(true)
  const [testBlocked, setTestBlocked] = useState(false)

  const recordingId = useRef(null)
  const blocked_message = useRef(null)
  const assets = useRef(null)

  useImperativeHandle(ref, () => ({
    start: () => {
      setPreTestOpen(true)
    }
  }))

  useEffect(() => {
    Auth.currentCredentials().then(c => setCurrentCredentials(c))
  }, [])

  useEffect(() => {
    const onResize = event => {
	    setWindowWidth(window.innerWidth)
	    setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const refId = urlParams.get('ref')
    
    let url_meta = {};
    for(var key of urlParams.keys()) { 
		url_meta[key] = urlParams.get(key); 
	}
	    
    let test_id_qs = urlParams.get("test_id");
    let test_id = (typeof test_id_qs !== 'undefined' && test_id_qs) ? test_id_qs : '6444a561-4c16-4508-bd5a-13a17f41ec10';

    if(process.env.REACT_APP_INSTANCE.indexOf('demo') > -1){
      test_id = process.env.REACT_APP_INSTANCE;
    }
    
    console.log('===== KANJO_VERSION:', process.env.REACT_APP_VERSION)
    console.log('===== KANJO_INSTANCE:', process.env.REACT_APP_INSTANCE)
    console.log('===== REACT_APP_BRANCH:', process.env.REACT_APP_BRANCH)
    console.log('===== KANJO_TEST_ID:', test_id)
    
    API.post('REST', '/RecordingInit', {
      body: {
        test_uuid: test_id,
        referral_id: refId
      }
    }).then(response => {
      console.log('response', response);
      if (response.active) {
        recordingId.current = response.uuid
        assets.current = response.assets
        setProcessing(false)
      } else {
        blocked_message.current = {heading:response.heading, message:response.message}
        setTestBlocked(true)
        setProcessing(false)
      }
    }).catch(error => console.log(error));
  }, [])

  const handleSaveData = params => {
	  
	const urlParams = new URLSearchParams(window.location.search)
    
    let url_meta = {};
    for(var key of urlParams.keys()) { 
		url_meta[key] = urlParams.get(key); 
	}
	
	for(let p in url_meta) { 
		if(typeof params[p] == 'undefined'){
			params[p] = url_meta[p];
		} 
	}
	
	console.log('**** handleSaveData ****');
	console.log('params', params);
	console.log('url_meta', url_meta);
	
  params['url_meta'] = url_meta;
  


	params['user_agent'] = window.navigator.userAgent;
	
	if(process.env.REACT_APP_INSTANCE.indexOf('demo') > -1){
      params['test_id'] = process.env.REACT_APP_INSTANCE;
    }
    
    console.debug('>>>>>> StoreDemographicData >>>>> params', params);
	
	return API.post('REST', '/StoreDemographicData', {
      body: {
        recording_uuid: recordingId.current,
        form_data: {...params}
      }
    });
  }

  const handleSaveEvents = events => {
    return API.post('REST', '/SaveAssetSyncData', {
      body: {
        recording_uuid: recordingId.current,
        data: events
      }
    })
  }

  const handleUpload = React.useCallback((_, data) => {
    const filename = `videos/${recordingId.current}/video.webm`;
  
    Storage.put(filename, data.blob, {
      contentType: data.type,
      progressCallback: ({loaded, total}) => {
        setUploadProgress((loaded / total) * 100)
      }
    }).then(stored => {
      setStored(stored)
      setTestRunning(false)
      setPostTestOpen(true)
    })
  }, [])

  const startTest = () => {
    setTestRunning(true)
    GTM.trackScreen('test-started')
  }

  const handlePreTestComplete = () => {
    setPreTestOpen(false)
    startTest()
  }

  const handleTestComplete = () => {
    setPostTestOpen(true)
    setTestRunning(false)
  }
  
  let mobile_message = {
	  heading: "We’re sorry.",
	  subheading: "This test isn’t available for mobile devices or tablets.",
	  message: "Please try again on a computer with a webcam."
  }
  
  let notchrome_message = {
	  heading: "Uh-oh, this survey requires Google Chrome",
	  subheading: "You can’t continue with your current web browser.",
    message: "<b>To continue...</b><br />1. Click the copy button below<br />2. Paste the address into the address bar in Google Chrome",
    strapline: 'Don’t have Google Chrome installed? You can download it <a href="https://www.google.com/intl/en_uk/chrome/" target="_blank">here</a>.', 
  }

  if(!isChrome) return <NotChrome input_data={notchrome_message} />

  if (windowWidth <= 1024) return <MobileWarning mobile_message={mobile_message} />
  
  if(windowHeight <= 776){
	  document.body.classList.add('small-screen');
  }else{
	  document.body.classList.remove('small-screen');
  }

  if (testBlocked) return <TestBlocked blocked_message={blocked_message.current} />

  if(process.env.REACT_APP_INSTANCE == 'demo' || process.env.REACT_APP_INSTANCE == 'demo_nspcc'){
	  return (
	    <>
	      <MediaStreamRecorder>
	        <PreTest
	          onComplete={handlePreTestComplete}
	          open={preTestOpen}
	          assetReady={true}
	          assetLoadedPercent="100"
	        />
	        <ABTest
	          open={testRunning}
	          uploader={handleUpload}
	          onComplete={handleTestComplete}
	        />
	        { /* <SingleAssetTest
	          open={testRunning}
	          uploader={handleUpload}
	          onComplete={handleTestComplete}
	          setAssetReady={setAssetReady}
	          testSpec={testSpec}
	          handleSaveEvents={handleSaveEvents}
	          assets={assets}
	          setAssetLoadedPercent={setAssetLoadedPercent}
	        /> */ }
	        <PostTest
	          open={postTestOpen}
	          saveData={handleSaveData}
	          stored={stored}
	          uploadProgress={uploadProgress}
	        />
	      </MediaStreamRecorder>
	      {processing && <ProcessingCover/>}
	    </>
	  )
  }

  if(process.env.REACT_APP_INSTANCE == 'participant'){
	  return (
	    <>
	      <MediaStreamRecorder>
	        <PreTest
	          onComplete={handlePreTestComplete}
	          open={preTestOpen}
	          assetReady={assetReady}
	          assetLoadedPercent={assetLoadedPercent}
	        />
	        <SingleAssetTest
	          open={testRunning}
	          uploader={handleUpload}
	          onComplete={handleTestComplete}
	          setAssetReady={setAssetReady}
	          testSpec={testSpec}
	          handleSaveEvents={handleSaveEvents}
	          assets={assets}
	          setAssetLoadedPercent={setAssetLoadedPercent}
	        />
	        <PostTest
	          open={postTestOpen}
	          saveData={handleSaveData}
	          stored={stored}
	          uploadProgress={uploadProgress}
	        />
	      </MediaStreamRecorder>
	      {processing && <ProcessingCover/>}
	    </>
	  )
  }
}

export default forwardRef(App)
