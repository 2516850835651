const React = require('react');
const ReactDOM = require('react-dom');
const { default: App } = require('./App');
// const * as serviceWorker from './serviceWorker';
// const { moduleLoader } from 'pnp-webpack-plugin';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

function createContainer () {
  const container = document.createElement('div')
  container.setAttribute('class', 'kanjo-container')
  const body = document.querySelector('body')
  body.appendChild(container)
  return container
}

export class Kanjo {
  constructor(options = {}) {
    const container = createContainer()
    this.app = React.createRef()
    ReactDOM.render(<App ref={this.app} />, container)
  }

  start() {
    if (this.app.current != null) {
      this.app.current.start()
    }
  }
}
