import React, { Fragment } from 'react'

const PostTestThankYou = (props) => {
  const msg = props.optInReport
    ? 'Ta da. All done. We’ll send results to you shortly.'
    : 'Ta da. All done.'
  
  //console.log('props', props);
  
  const urlParams = new URLSearchParams(window.location.search)
  const redirectto = urlParams.get('redirectto')
  if(redirectto){
	  setTimeout(() => {
		  window.location.href = redirectto;
	  }, 3000);
  }
  
/*
  useEffect(() => {
    if (uploadProgress >= 100 && activeSection === 3) {
      next()
    }
  }, [uploadProgress, activeSection])
*/
  
  if(props.uploadProgress !== 100){
	  return (
	    <div className="k-demo-form__finish" data-open="true">
	      <h2>Thanks for participating in this session.</h2>
	      <h3>Hold on just a moment longer – we’ll let you know when we’re done.</h3>
	    </div>
	  )
  }
  if(redirectto){
	  return (
	    <Fragment>
	      <h2>{msg}</h2>
	      <h3>Thanks for taking part. You will be redirected shortly. <br />If you are not redirected after 5 seconds please click <a href={redirectto}>here</a>.</h3>
	    </Fragment>
	  )
  }
  return (
    <Fragment>
      <h2>{msg}</h2>
      <h3>Thanks for taking part. You may now close this window.</h3>
    </Fragment>
  )
}

export default PostTestThankYou
