import './Terms.css'

import React from 'react'

import Logo from '../Logo'
import Button from '../Button'

import closeIcon from './close.svg'
import bgImg from './bg2.png'

const Terms = props => {
	const {
		close,
		privacyPolicy,
		cookiesPolicy,
		preloadBackgroundOnly,
	} = props
	
	if(preloadBackgroundOnly){
		const img = new Image();
		img.src = bgImg;
		return (null)
	}
	
	let usePrivacyContent = (privacyPolicy) ? true : false;
	let useCookiesContent = (cookiesPolicy && privacyPolicy) ? true : false;
	
	if(useCookiesContent){
		usePrivacyContent = false;
	}

	return (
		<div className="terms">
			<div className="terms__inner">
				<div className="terms__logo">
					<Logo></Logo>
					<img className="terms__close" src={closeIcon} alt="" onClick={close}/>
				</div>
				<div className="terms__copy">
					{usePrivacyContent ? <PrivacyContent/> : null}
					{useCookiesContent ? <CookiesContent/> : null}
					{!usePrivacyContent && !useCookiesContent ? <TermsContent/> : null}
				</div>
				<p className="terms__btn">
					<Button handleClick={close}>That's OK with me</Button>
				</p>
			</div>
		</div>
	)
}

const TermsContent = () => {
	return (
		<>
			<h2>TERMS OF USE OF THE PARTICIPANT URL</h2>
<ul>
  <li><strong>THESE TERMS OF USE APPLY TO ALL USERS OF THE PARTICIPANT URL.</strong></li>
  <li><strong>PLEASE READ CAREFULLY BEFORE ACCESSING THE PARTICIPANT URL. </strong></li>
  <li><strong>WE (KANJO TECHNOLOGIES LIMITED, SEE BELOW) ARE THE PROPRIETORS OF THE PARTICIPANT URL WHICH YOU ARE USING TO TAKE A TEST.</strong></li>
  <li><strong>THIS DOCUMENT DETAILS THE BASIS ON WHICH YOU MAY USE THE PARTICIPANT URL. </strong></li>
</ul>
<p><strong>____________________________________________________________________________________</strong></p>
<ul>
  <li>BY USING THE PARTICIPANT URL YOU AGREE TO THESE TERMS OF USE AND IN PARTICULAR, THE EXCLUSIONS OF LIABILITY IN SECTION 9 BELOW.</li>
  <li>IF YOU DO NOT AGREE TO THESE TERMS OF USE, WE WILL NOT LICENSE USE OF THE PARTICIPANT URL TO YOU AND YOU MUST DISCONTINUE ACCESS IMMEDIATELY. IN THIS CASE YOU MAY NOT ACCESS ANY SOFTWARE, DOCUMENTS OR INFORMATION FROM THE PARTICIPANT URL.</li>
</ul>
<p><strong>&nbsp;____________________________________________________________________________________</strong></p>
<p><strong>ABOUT US</strong></p>
<p>We are Kanjo Technologies Limited (12576128) of 20-22 Wenlock Road, London, England, N1 7GU (<strong>us</strong>, <strong>we </strong>or<strong> our</strong>).</p>
<p><strong>YOUR PERMISSION TO USE THIS PARTICIPANT URL</strong></p>
<p>We license use of the Participant URL to you on the basis of these Terms of Use. We do not sell the Participant URL, the software or code in the Participant URL or documents relating the Participant URL, or any part of it (the &ldquo;<strong>Participant URL</strong>&rdquo;). As between you and us, we remain the owners of the Participant URL at all times.</p>
<p>CHANGES TO THESE TERMS OF USE</p>
<p>We may revise these Terms of Use at any time by amending this page. Please check this page from time to time to take notice of any changes we made, as they are binding on you.</p>
<p><strong>You should print a copy of these Terms of Use for future reference.</strong></p>
<ol>
  <li>PERMISSION TO USE THE PARTICIPANT URL
    <ul>
      <li>The Participant URL facilitates individuals to participate in a the test where the participant views content and responses are recorded (&ldquo;<strong>Test</strong>&rdquo;).</li>
      <li>We give you a non-exclusive, non-transferable right to use the Participant URL by means of internet browser solely for the purpose of taking a Test.</li>
    </ul>
  </li>
  <li>RESTRICTIONS
    <ul>
      <li>You shall not use the Participant URL for any other purpose than its intended purpose as detailed in clause 1.</li>
      <li>You must not give access to any other third party except as necessary for the intended purpose detailed in clause 1.</li>
      <li>You shall not:-
        <ul>
          <li>attempt to copy, alter, modify, reverse engineer, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Participant URL, software, documentation nor downloads in any form or media by any means;</li>
          <li>access all or any part of the Participant URL in order to build a product or service which competes with the Participant URL, software ore related documentation;</li>
          <li>use the Participant URL, software, documentation or downloads to provide services to third parties other than as permitted under clause 1; or</li>
          <li>license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Participant URL, software, documentation or downloads available to any third party except as required for the intended purpose under clause 1; or</li>
          <li>attempt to obtain, or assist third parties in obtaining access to the Participant URL, software, documentation or downloads except as required for the intended purpose under clause 1.</li>
        </ul>
      </li>
      <li>If you become aware of any unauthorised access or use of the Participant URL, software, documentation or downloads you shall promptly inform us.</li>
    </ul>
  </li>
  <li>CLIENT CONFIDENTIALITY
    <ul>
      <li>For the purpose of this clause 3, &ldquo;Confidential Information&rdquo; shall mean all content displayed as part of the Test including, without limitation, company names, products, branding, themes, adverts, images, videos.</li>
      <li>Without prejudice to your common law duties, you shall not (except as authorised or required by law or as authorised by us), either during or at any time after your participation in the Test:
        <ul>
          <li>use any Confidential Information; or</li>
          <li>make or use any copies of the Confidential Information; or</li>
          <li>disclose any Confidential Information to any person, company or other organisation whatsoever.</li>
        </ul>
      </li>
      <li>The restriction in clause 2 does not apply to any Confidential Information which is or comes into the public domain other than through your unauthorised disclosure.</li>
      <li>You shall be responsible for protecting the confidentiality of the Confidential Information and shall:
        <ul>
          <li>use your best endeavours to prevent the use or communication of any Confidential Information by any person, company or organisation; and</li>
          <li>inform us immediately on becoming aware, or suspecting, that any such person, company or organisation knows or has used any Confidential Information.</li>
        </ul>
      </li>
      <li>All Confidential Information shall be our property and on completion of the Test, you shall:
        <ul>
          <li>irretrievably delete any Confidential Information stored on any magnetic or optical disk or memory, including computer networks, e-mail accounts or personal accounts on websites, and all matter derived from such sources which is in your possession or under your control; and</li>
          <li>provide a signed statement that you have complied fully with your obligations under this clause 3.</li>
        </ul>
      </li>
    </ul>
  </li>
</ol>
<ol start="4">
  <li>VIRUSES
    <ul>
      <li>We do not guarantee that the Participant URL will be secure or free from bugs or viruses.</li>
      <li>You are responsible for configuring your information technology, computer programmes and platform in order to access the Participant URL. You should use your own virus protection software.</li>
      <li>You must not misuse the Participant URL by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to the Participant URL, the server on which the Participant URL is stored or any server, computer or database connected to the Participant URL. You must not attack the Participant URL via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Participant URL will cease immediately.</li>
    </ul>
  </li>
  <li>LINKING TO THE PARTICIPANT URL
    <ul>
      <li>You must not establish a link with the Participant URL in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</li>
    </ul>
  </li>
  <li>INTELLECTUAL PROPERTY RIGHTS
    <ul>
      <li>You acknowledge that as between you and us all intellectual property rights in the Participant URL, software and the documentation anywhere in the world belong to us, that rights in the software and downloads are licensed (not sold) to you, and that you have no rights in, or to, Participant URL, software and the documentation and the downloads other than the right to use them in accordance with these Terms of Use.</li>
      <li>You acknowledge that you have no right to have access to the Participant URL, of software or code in the Participant URL or any part of it in source code form.</li>
    </ul>
  </li>
  <li>EXCLUSION OF LIABILITY
    <ul>
      <li>Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.</li>
      <li>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to Participant URL, software and the documentation and any content on the Participant URL, whether express or implied.</li>
      <li>We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
        <ul>
          <li>use of, or inability to use, the Participant URL; or</li>
          <li>use of or reliance on any content displayed on the Participant.</li>
        </ul>
      </li>
      <li>In particular, we will not be liable for:
        <ul>
          <li>loss of profits, sales, business, or revenue;</li>
          <li>business interruption;</li>
          <li>loss of anticipated savings;</li>
          <li>loss of business opportunity, goodwill or reputation; or</li>
          <li>any indirect or consequential loss or damage.</li>
        </ul>
      </li>
      <li>We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Participant URL or to your downloading of any content on it, or on any website linked to it.</li>
      <li>We assume no responsibility for the content of websites linked on the Participant URL. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.</li>
    </ul>
  </li>
  <li>TERMINATION
    <ul>
      <li>We may terminate the licence immediately if you commit a breach of these Terms of Use.</li>
      <li>Upon termination for any reason:
        <ul>
          <li>all rights granted to you under these Terms of Use shall cease;</li>
          <li>you must immediately cease using the Participant URL; and</li>
          <li>you must immediately delete or remove the information from the Participant URL from all computer equipment in your possession, and immediately destroy or return to us (at our option) all copies of the Participant URL, software and documentation then in your possession, custody or control and, in the case of destruction, certify to us that you have done so.</li>
        </ul>
      </li>
    </ul>
  </li>
</ol>
<ol start="9">
  <li>GENERAL
    <ul>
      <li>We may transfer our rights and obligations under Terms of Use to another organisation, but this will not affect your rights or our obligations under these Terms of Use.</li>
      <li>You may only transfer your rights or your obligations under these Terms of Use to another person if we agree in writing.</li>
      <li>These Terms of Use constitute the entire agreement between us and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between us, whether written or oral, relating to its subject matter. You agree that you shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in these Terms of Use. You agree that you shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this in these Terms of Use or any document expressly referred to in it.</li>
      <li>If we fail to insist that you perform any of your obligations under these Terms of Use, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</li>
      <li>Each of the conditions of these Terms of Use operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.</li>
      <li>These Terms of Use, its subject matter and its formation (and any non-contractual disputes or claims) are governed by English law. We both irrevocably agree to the exclusive jurisdiction of the courts of England and Wales.</li>
    </ul>
  </li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>


		</>
	)
}

const PrivacyContent = () => {
	return (
        <>
            <h2>PARTICIPANT PRIVACY NOTICE</h2>
<p>____________________________________________________________</p>
<p>&nbsp;</p>
<p>Welcome to Kanjo&rsquo;s privacy policy.</p>
<p>&nbsp;</p>
<p>Kanjo respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you access our <em><u>Participant URL</u></em> (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
<p>&nbsp;</p>
<p>Please use the <u>Glossary </u>to understand the meaning of some of the terms used in this privacy policy.</p>
<p>&nbsp;</p>
<p>CONTENTS</p>
<p>&nbsp;</p>
<ol>
  <li><u> IMPORTANT INFORMATION AND WHO WE ARE</u></li>
  <li><u> THE DATA WE COLLECT ABOUT YOU</u></li>
  <li><u> HOW IS YOUR PERSONAL DATA COLLECTED?</u></li>
  <li><u> HOW WE USE YOUR PERSONAL DATA</u></li>
  <li><u> DISCLOSURES OF YOUR PERSONAL DATA </u></li>
  <li><u> INTERNATIONAL TRANSFERS </u></li>
  <li><u> DATA SECURITY</u></li>
  <li><u> DATA RETENTION </u></li>
  <li><u> YOUR LEGAL RIGHTS </u></li>
  <li><u> GLOSSARY </u></li>
</ol>
<p>&nbsp;</p>
<p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Important information and who we are</p>
<ul>
  <li>This privacy policy aims to give you information on how Kanjo collects and processes your personal data through your use of the <em><u>Participant URL</u></em>, including any data you may provide through the <em><u>Participant URL</u></em> when you take part in the <em><u>Test</u></em>.</li>
  <li>The <em><u>Participant URL</u></em> is not intended for children and we do not knowingly collect data relating to children.</li>
  <li>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data.</li>
  <li>Kanjo Technologies Limited (12576128) is the data controller and responsible for your personal data (collectively referred to as "Kanjo", "we", "us" or "our" in this privacy policy).</li>
  <li>We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.</li>
  <li>Contact details
    <ul>
      <li>If you have any questions about this privacy policy or our privacy practices, please contact our data privacy manager in the following ways:
        <ul>
          <li>Email address: admin@Kanjoapp.com</li>
          <li>Postal address: 28 Poole Hill, Bournemouth, BH2 5PS.</li>
        </ul>
      </li>
      <li>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</li>
      <li>We keep our privacy policy under regular review.</li>
      <li>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</li>
      <li>This <em><u>Participant URL</u></em> may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave the <em><u>Participant URL</u></em>, we encourage you to read the privacy policy of every website you visit.</li>
    </ul>
  </li>
</ul>
<p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The data we collect about you</p>
<ul>
  <li>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</li>
  <li>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:
    <ul>
      <li><strong>Identity Data</strong>
        <ul>
          <li>Contact Data - your name and e-mail address</li>
          <li>Demographic Data - gender, age, occupation and location</li>
          <li>Participant Recordings - (a) a video recording of your face and still frames taken from those video recordings; (b) a recording of your screen with the relevant stimuli; (c) a recording of your audio environment during the <em>Test</em>.</li>
        </ul>
      </li>
      <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this <em><u>Participant URL</u></em>.</li>
      <li><strong>Profile Data </strong>includes your interests, preferences, feedback and Test responses.</li>
      <li><strong>Usage Data </strong>includes information about how you use the <em><u>Participant </u></em><u></u></li>
      <li><strong>Marketing &amp; Communications Data </strong>includes information and preferences to contact you with marketing information.</li>
    </ul>
  </li>
  <li>We also collect, use and share aggregated data such as your statistical or demographic data or profile data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</li>
  <li>We <strong>do not</strong> collect any:
    <ul>
      <li>Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data for the purposes of uniquely identifying you).</li>
      <li>Information about criminal convictions and offences.</li>
    </ul>
  </li>
</ul>
<p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; How is your personal data collected?</p>
<ul>
  <li>We use different methods to collect data from and about you including through:</li>
</ul>
<table>
  <tbody>
    <tr>
      <td width="156">
        <p>Direct interactions.</p>
      </td>
      <td width="540">
        <p>All personal data we collect about you will be collected via the <em><u>Participant URL</u></em>, which is accessed by the link we send you.&nbsp; This includes personal data you provide when you:&nbsp;</p>
        <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; complete the <em><u>Test</u></em>; or</p>
        <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; request marketing to be sent to you.</p>
        <p>&nbsp;</p>
        <p>You may also provide us with personal data by corresponding with us by post, phone, email or otherwise.</p>
        <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to enter a competition, promotion or survey;</p>
        <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to give us feedback; or</p>
        <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; in relation to any of your legal rights in relation to your personal data.</p>
        <p>&nbsp;</p>
      </td>
    </tr>
    <tr>
      <td width="156">
        <p>Automated technologies or interactions.</p>
      </td>
      <td width="540">
        <p>As you interact with the <em><u>Participant URL</u></em>, we may automatically collect <em><u>Technical Data</u></em> about your equipment, browsing actions and patterns. We may collect this personal data by using cookies, server logs and other similar technologies. We may also receive <em><u>Technical Data</u></em> about you if you visit other websites employing our cookies. Please see our <em><u>Cookie Policy</u></em> for further details.</p>
        <p>&nbsp;</p>
        <p>Your <em><u>Contact Data</u></em> and other personal data will not be uploaded until you have successfully completed the <em><u>Test</u></em>at which point it will be processed by us in accordance with this Privacy Policy.</p>
        <p>&nbsp;</p>
      </td>
    </tr>
    <tr>
      <td width="156">
        <p>Third parties or publicly available sources.</p>
      </td>
      <td width="540">
        <p>We will receive personal data about you from various third parties and public sources as set out below.</p>
        <p><em><u>Technical Data</u></em> from the following parties:</p>
        <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; analytics providers such as Google (based outside the EEA).</p>
        <p>&nbsp;</p>
        <p>Your <em><u>Contact Data</u></em> from third parties such as:</p>
        <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <em><u>our Clients</u></em>; and/ or</p>
        <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recruiters of participants for <em><u>Tests</u></em> &nbsp;or providers of contact lists.</p>
      </td>
    </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; How we use your personal data</p>
<ul>
  <li>By law, we must be able to point to a legal ground for using your personal data. This is known as the &ldquo;lawful basis&rdquo; for processing. (See Column C in the Data Processing Activity Table in <em><u>Section </u></em><em><u>6</u></em> below. )</li>
  <li>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</li>
  <li>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</li>
  <li>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
    <ul>
      <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
      <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
      <li>Where we need to comply with a legal obligation.</li>
    </ul>
  </li>
  <li>Generally, we do not rely on consent as a legal basis for processing your personal data (except for Participant Recordings).</li>
  <li>Data Processing Activity Table</li>
</ul>
<table>
  <tbody>
    <tr>
      <td width="313">
        <p>Column A</p>
        <p>&nbsp;</p>
      </td>
      <td width="126">
        <p>Column B</p>
      </td>
      <td width="258">
        <p>Column C</p>
      </td>
    </tr>
    <tr>
      <td width="313">
        <p>Purpose/Activity</p>
      </td>
      <td width="126">
        <p>Type of data</p>
      </td>
      <td width="258">
        <p>Lawful basis for processing including basis of legitimate interest</p>
      </td>
    </tr>
    <tr>
      <td width="313">
        <p>To ensure that your hardware devices are properly aligned and able to allow you to take the <em><u>Test</u></em>.</p>
        <p>&nbsp;</p>
        <p>To use data analytics to improve the <em><u>Participant URL</u></em>, the Participant experience, products/services, marketing, customer relationships and experiences.</p>
        <p>&nbsp;</p>
        <p>The Cookies associated with this are detailed in the <em><u>Cookie Policy</u></em>.</p>
        <p>&nbsp;</p>
      </td>
      <td width="126">
        <p>(a) <em><u>Technical Data</u></em></p>
        <p>(b) <em><u>Usage Data</u></em></p>
        <p><em>&nbsp;</em></p>
      </td>
      <td width="258">
        <p>&middot;&nbsp; Necessary for our legitimate interests to enable you to perform the <em><u>Test.</u></em></p>
        <p>&middot;&nbsp; Necessary for our legitimate interests (to define Participant experience for our products and services, to improve the <em><u>Participant URL</u></em>, to develop our business and to inform our marketing strategy).</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;</p>
        <p>&nbsp;</p>
      </td>
    </tr>
    <tr>
      <td width="313">
        <p>To manage our relationship with you which will include:</p>
        <p>(a) Notifying you about changes to the privacy policy;</p>
        <p>(b) Asking you to leave a review or take a survey as to your experience as a Participant;</p>
        <p>(c) to be able to provide you with&nbsp; Test Results; and</p>
        <p>(d) incentives</p>
        <p>&nbsp;</p>
      </td>
      <td width="126">
        <p>(a) &nbsp;<em><u>Contact Data</u></em></p>
        <p>(b) <em><u>Profile Data</u></em></p>
        <p>(c) <em><u>Marketing and Communications Data</u></em></p>
      </td>
      <td width="258">
        <p>&middot;&nbsp;&nbsp; Consent (for Participant Recordings).</p>
        <p>&middot;&nbsp;&nbsp; Necessary to comply with a legal obligation.</p>
        <p>&middot;&nbsp;&nbsp; Necessary for our legitimate interests (to keep our records updated and to study how Participants use our products/services).</p>
      </td>
    </tr>
    <tr>
      <td width="313">
        <p>To enable you to complete the <em><u>Test</u></em></p>
      </td>
      <td width="126">
        <p>(a) <em><u>Identity Data</u></em></p>
        <p>(b) <em><u>Profile Data</u></em></p>
        <p>(c) <em><u>Usage Data</u></em></p>
        <p>(d) <em><u>Marketing and Communications Data</u></em>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>&nbsp;</p>
      </td>
      <td width="258">
        <p>&middot;&nbsp; Performance of a contract with you.</p>
        <p>&middot;&nbsp; Necessary for our legitimate interests (to study how &nbsp;Participants respond to given images).</p>
      </td>
    </tr>
    <tr>
      <td width="313">
        <p>To administer and protect our business and this <em><u>Participant URL</u></em>(including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).&nbsp;</p>
      </td>
      <td width="126">
        <p>(a) <em><u>Identity Data</u></em></p>
        <p>(b) <em><u>Contact Data</u></em></p>
        <p>(c) <em><u>Technical Data</u></em></p>
      </td>
      <td width="258">
        <p>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise).</p>
        <p>(b) <em><u>Necessary to comply with a legal obligation</u></em></p>
        <p>&nbsp;</p>
      </td>
    </tr>
    <tr>
      <td width="313">
        <p>To use data analytics to improve the &nbsp;<em><u>Participant URL</u></em>products/services, marketing, customer relationships and experiences.</p>
      </td>
      <td width="126">
        <p>(a) <em><u>Technical Data</u></em></p>
        <p>(b) <em><u>Usage Data</u></em></p>
      </td>
      <td width="258">
        <p>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our <em><u>Participant URL</u></em> updated and relevant, to develop our business and to inform our marketing strategy).</p>
      </td>
    </tr>
    <tr>
      <td width="313">
        <p>To make suggestions and recommendations to you about goods or services that may be of interest to you.</p>
      </td>
      <td width="126">
        <p>(a) <em><u>Identity Data</u></em></p>
        <p>(b) <em><u>Technical Data</u></em></p>
        <p>(c) <em><u>Usage Data</u></em></p>
        <p>(d) <em><u>Profile Data</u></em></p>
        <p>(e) <em><u>Marketing &amp; Communications Data</u></em></p>
      </td>
      <td width="258">
        <p>Necessary for our legitimate interests (to develop our products/services and grow our business).</p>
      </td>
    </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<ul>
  <li>Marketing
    <ul>
      <li>We may use your <em><u>Identity Data</u></em>, <em><u>Technical Data</u></em>, <em><u>Usage Data</u></em> and <em><u>Profile Data</u></em> to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</li>
      <li>You will not receive marketing communications from us unless you have consented to do so. (We may still contact you in relation to a survey regarding your feedback of your experience as a Participant).</li>
      <li>You will be able to opt out at any time by <em><u>contacting us</u></em> as detailed in <em><u>Section </u></em><em><u>6</u></em>.</li>
    </ul>
  </li>
  <li>Cookies
    <ul>
      <li>You can set your browser to refuse all or some browser cookies, or to alert you when the Participant URL sets or accesses cookies. If you disable or refuse cookies, please note that some parts of the <em><u>Participant URL</u></em> may become inaccessible or not function properly. For more information about the cookies we use, please see <em><u>Cookie Policy</u></em>.</li>
    </ul>
  </li>
  <li>Change of purpose
    <ul>
      <li>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</li>
      <li>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</li>
      <li>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</li>
    </ul>
  </li>
</ul>
<p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disclosures of your personal data</p>
<p>We may share your personal data with the parties set out below for the purposes set out in the <em><u>Data Processing Activity Table</u></em> in Section 4.6 above.</p>
<ul>
  <li><strong>External Third Parties</strong>
    <ul>
      <li>Service providers acting as processors some of which are based in the United Kingdom and some outside the EEA who provide IT and system administration services.</li>
      <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in the United Kingdom who provide consultancy, banking, legal, insurance and accounting services.</li>
      <li>HM Revenue &amp; Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances.</li>
      <li><em><u>Kanjo Clients</u></em> : your <em><u>Test Results</u></em> will be shared with <em><u>Kanjo Clients.</u></em></li>
      <li>Service providers who act as recruiters or managers or Participants schemes/ Participant incentives in general.</li>
    </ul>
  </li>
  <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</li>
  <li>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</li>
</ul>
<p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; International transfers</p>
<ul>
  <li>Many of our external third parties are based outside the EEA so their processing of your personal data will involve a transfer of data outside the EEA.</li>
  <li>Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
    <ul>
      <li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.</li>
      <li>Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries.</li>
      <li>Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. For further details, see European Commission: EU-US Privacy Shield.</li>
    </ul>
  </li>
  <li>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</li>
</ul>
<p>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Data security</p>
<ul>
  <li>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</li>
  <li>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</li>
</ul>
<p>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Data retention</p>
<ul>
  <li>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</li>
  <li>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</li>
  <li>In some circumstances you can ask us to delete your data: see <em><u>your legal rights</u></em> in <em><u>Section </u></em><em><u>9</u></em> below for further information.</li>
  <li>In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</li>
</ul>
<p>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your legal rights</p>
<ul>
  <li>Under certain circumstances, you have rights under data protection laws in relation to your personal data. We have explained more about these below. You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</li>
</ul>
<table>
  <tbody>
    <tr>
      <td width="175">
        <p>Your legal right</p>
      </td>
      <td width="489">
        <p>What it means</p>
      </td>
    </tr>
    <tr>
      <td width="175">
        <p>Request access to your personal data (commonly known as a "data subject access request").</p>
      </td>
      <td width="489">
        <p>This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
      </td>
    </tr>
    <tr>
      <td width="175">
        <p>Request correction of the personal data that we hold about you.</p>
      </td>
      <td width="489">
        <p>This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
        <p>&nbsp;</p>
      </td>
    </tr>
    <tr>
      <td width="175">
        <p>Request erasure of your personal data.</p>
      </td>
      <td width="489">
        <p>This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
      </td>
    </tr>
    <tr>
      <td width="175">
        <p>Object to processing your personal data in certain situations.</p>
      </td>
      <td width="489">
        <p>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms.</p>
        <p>You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
      </td>
    </tr>
    <tr>
      <td width="175">
        <p>Request restriction of processing of your personal data.</p>
      </td>
      <td width="489">
        <p>This enables you to ask us to suspend the processing of your personal data in the following scenarios:</p>
        <p>(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you want us to establish the data's accuracy.</p>
        <p>(ii)&nbsp;&nbsp;&nbsp;&nbsp; Where our use of the data is unlawful but you do not want us to erase it.</p>
        <p>(iii)&nbsp;&nbsp;&nbsp; Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</p>
        <p>(iv)&nbsp;&nbsp;&nbsp; You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
      </td>
    </tr>
    <tr>
      <td width="175">
        <p>Request the transfer of your personal data to you or to a third party.</p>
      </td>
      <td width="489">
        <p>We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>
      </td>
    </tr>
    <tr>
      <td width="175">
        <p>Withdraw consent at any time where we are relying on consent to process your personal data.</p>
      </td>
      <td width="489">
        <p>However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
      </td>
    </tr>
  </tbody>
</table>
<ul>
  <li>If you wish to exercise any of the rights set out above, please <em><u>Contact us</u></em> as detailed in <em><u>Section </u></em><em><u>6</u></em> above.</li>
  <li><strong>No fee usually required</strong> You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</li>
  <li><strong>What we may need from you</strong> We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</li>
  <li><strong>Time limit to respond</strong> We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</li>
  <li><strong>Partial anonymisation/ pseudonymisation</strong>: Because we have taken measures to store your personal data in a way that it cannot be associated with your identity it may not always be possible to retrieve your personal data from our database. We are happy to explain this in more detail of you wish to contact us or should you exercise any your legal rights and this is the case we will explain this to you at the time.</li>
</ul>
<p>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Glossary</p>
<ul>
  <li><strong>Comply with a legal obligation</strong>: means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</li>
  <li><strong>Contact Data</strong>: as defined in <em><u>Section </u></em><em><u>2.1(i)</u></em></li>
  <li><strong>Data Processing Activity Table</strong>: as detailed in <em><u>Section 4.6</u></em></li>
  <li><strong>Demographic Data</strong>: as defined in <em><u>Section </u></em><em><u>2.1(ii)</u></em></li>
  <li><strong>Identity Data</strong>: <em><u>Contact Data</u></em>, <em><u>Demographic Data</u></em> and <em><u>Participant Recordings</u></em></li>
  <li><strong>Kanjo Clients</strong>: the clients whom we perform <em><u>Tests</u></em> for and provide <em><u>Visualisations</u></em></li>
  <li><strong>Legitimate Interest</strong>: means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</li>
  <li><strong>Marketing and Communications Data</strong>: as defined in <em><u>Section </u></em><em><u>2.5</u></em></li>
  <li><strong>Participant</strong>: the person taking the <em><u>Test</u></em>.</li>
  <li><strong>Participant Recordings</strong>: as defined in <em><u>Section </u></em><em><u>2.1(iii)</u></em></li>
  <li><strong>Participant</strong> <strong>URL</strong>: the URL through which the <em><u>Participant </u></em>accesses a set of screens to take the <u></u></li>
  <li><strong>Performance of Contract</strong>: means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
  <li><strong>Profile Data</strong>: as defined in <em><u>Section </u></em><em><u>2.3</u></em></li>
  <li><strong>Technical Data</strong>: as defined in <em><u>Section </u></em><em><u>2.2</u></em></li>
  <li><strong>Test</strong>: the test that you take via the <em><u>Participant URL</u></em> where you view images.</li>
  <li><strong>Test Results</strong>: this is the outcome of the <em><u>Test</u></em> you took.</li>
  <li><strong>Usage Data</strong>: as defined in <em><u>Section </u></em><em><u>2.4</u></em></li>
  <li><strong>Visualisations</strong>: the report containing the <em><u>Test Results</u></em></li>
</ul>
<p>&nbsp;</p>
        </>
	)
}

const CookiesContent = () => {
	return (
        <>
            <h2>Cookies</h2>
<p>This Participant URL uses cookies to distinguish you from other Participants taking the Participant Test. This helps us to provide you with a good experience when you use our Participant URL and also allows us to improve it&rsquo;s usability as well as allowing you to set up your devices to connect with the Participant URL to take the Participant Test.</p>
<p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
<ol start="2">
  <li>Types of Cookies we use</li>
</ol>
<p>We use the following cookies:</p>
<p><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of the Participant URL. They include, for example, cookies that enable you to align your hardware devices to enable you to properly take the Participant Test.</p>
<p><strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around the Participant URL when they are using it. This helps us to improve the way the Participant URL looks, for example, by ensuring that the Participant experience is improved.&nbsp;</p>
<ol start="3">
  <li>Details of Cookies we use</li>
</ol>
<p>You can find more information about the individual cookies we use and the purposes for which we use them in the table below:</p>
<table width="657">
  <tbody>
    <tr>
      <td width="174">
        <p><strong>Cookie Title</strong></p>
        <p><strong>Cookie Name</strong></p>
      </td>
      <td width="276">
        <p><strong>Purpose and cookie expiry</strong></p>
      </td>
      <td width="207">
        <p><strong>More information</strong></p>
      </td>
    </tr>
    <tr>
      <td width="174">
        <p>Analytical or performance cookies</p>
        <p>Google Analytics</p>
      </td>
      <td width="276">
        <p>This cookie enables us to:</p>
        <p>_ga Used to distinguish users - 2 years</p>
        <p>_gid Used to distinguish users - 24 hours</p>
        <p>_gat Used to throttle request rate - 1 minute</p>
      </td>
      <td width="207">
        <p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></p>
      </td>
    </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<ol start="4">
  <li>Third Party Cookies
    <ul>
      <li>Please note that the following third parties may also use cookies, over which we have no control. These named third parties may include, for example, advertising networks and providers of external services like web traffic analysis services. These third party cookies are likely to be analytical cookies or performance cookies or targeting cookies:
        <ul>
          <li>Google Analytics</li>
        </ul>
      </li>
      <li>We do not share the information collected by the cookies with any third parties.</li>
      <li>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of the Participant URL.</li>
    </ul>
  </li>
</ol>
        </>
	)
}

export default Terms
