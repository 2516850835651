import React, { useEffect, useRef, useState } from 'react'

import PostTestFinished from './PostTestFinished'
import PostTestDemoForm from './PostTestDemoForm'
import PostTestSurvey from './PostTestSurvey'
import PostTestThankYou from './PostTestThankYou'
import UploadHeader from './UploadHeader'

import GTM from '../../lib/gtm'

const steps = {
  finished: {
    name: 'finished',
    component: PostTestFinished,
    nextStep: 'survey'
  },
  demoForm: {
    name: 'demoform',
    component: PostTestDemoForm,
    nextStep: 'thankYou'
  },
  survey: {
    name: 'survey',
    component: PostTestSurvey,
    nextStep: 'thankYou'
  },
  thankYou: {
    name: 'thankyou',
    component: PostTestThankYou,
  }
}

const PostTest = (props) => {
  const {
    onComplete,
    open,
    saveData,
    uploadProgress
  } = props;
  
  let form_step = steps.demoForm;
  
  if(process.env.REACT_APP_INSTANCE == 'demo'){
	  form_step = steps.survey;
  }
  if(process.env.REACT_APP_INSTANCE == 'demo_nspcc'){
	  form_step = steps.survey;
  }
  if(process.env.REACT_APP_INSTANCE == 'participant'){
	  form_step = steps.demoForm;
  }

  const prevOpen = useRef()
  const [step, setStep] = useState(form_step)
  const [optInReport, setOptInReport] = useState(false)

  useEffect(() => {
    if (!open) return
    GTM.trackScreen('posttest ' + step.name)
  }, [step, open])

  const handleNext = () => {
    if (step.nextStep != null) {
      setStep(steps[step.nextStep])
    } else if (onComplete) {
      onComplete()
    }
  }

  return (
    <div className="k-post-test" data-stage={step.name} data-open={open}>
      {/*step.name !== 'thankyou' && <UploadHeader uploadProgress={uploadProgress}/>*/}
      {uploadProgress !== 100 && <UploadHeader uploadProgress={uploadProgress}/>}
      <div className="k-post-test__stage">
        <step.component
          next={handleNext}
          optInReport={optInReport}
          saveData={saveData}
          setOptInReport={setOptInReport}
          uploadProgress={uploadProgress}
        ></step.component>
      </div>
    </div>
  )
}

export default PostTest
