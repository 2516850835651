import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

// import _video from './test.mp4'
// import video from './tango-granny.mp4'
import ripples from './ripples.svg'
import './SingleAssetTest.css'

import { useMediaStreams } from '../MediaStreamRecorder'
import GTM from '../../lib/gtm'


const SingleAssetTest = props => {
  const { 
    open,
    uploader,
    onComplete,
    setAssetReady,
    handleSaveEvents,
	assets,
	setAssetLoadedPercent
  } = props;
    
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const [seqStarted, setSeqStarted] = useState(false)
  const [showNeutral, setShowNeutral] = useState(false)
  const [showAsset, setShowAsset] = useState(false)
  const [events, setEvents] = useState([])

  const sessionStartTime = useRef(null)
  const videoRef = useRef(null)
  let video = useRef(null)
  
  const mediaStreams = useMediaStreams()

  useEffect(() => {
    if (open && !seqStarted) {
      mediaStreams.record(uploader)
      startSequence()
    }
  }, [open])
  
  useEffect(() => {
    if (!showAsset) return

    const timer = setInterval(() => {
      if (!showAsset) return
      videoTick()
    }, 200)

    return () => clearInterval(timer)
  }, [showAsset])

  useLayoutEffect(() => {

  }, [seqStarted])

  const videoTick = () => {
    addEvent({
      type: 'tick',
      sectionName: 'asset_1',
      sectionElapsed: videoRef.current.currentTime
    })
  }

  const getElapsed = () => {
    const now = new Date()
    return (now - sessionStartTime.current) / 1000;
  }

  const addEvent = event => {
    setEvents(events => [...events, {...event, sessionElapsed: getElapsed()}])
  }

  const onVideoPlay = () => {
    addEvent({
      type: 'section_start',
      sectionName: 'asset_1',
    })
    videoTick()
  }

  const onVideoReady = () => {
    //console.log('onVideoReady');
    //setAssetReady(true)
  }

  const onVideoMeta = () => {
    //console.log('onVideoMeta');
    //setAssetReady(true)
  }
  
  const onVideoProgress = () => {
	//const now = new Date();
    //console.log('onVideoProgress', now);
    //setAssetReady(true)
  }
  
  const onVideoPlaying = () => {
	//const now = Date.now();
	//console.log('onVideoPlaying', now);
  }
  
  const onVideoTimeUpdate = () => {
	const now = Date.now();
    if(videoRef.current.currentTime > 0 && !videoIsPlaying){
	    //console.log('onVideoTimeUpdate', now);
	    setVideoIsPlaying(true);
	    onVideoPlay();
    }
  }

  const onVideoLoadStart = () => {
    
    //console.log('onVideoLoadStart', videoRef.current.buffered);
    //console.log('assets.current[0].meta.url', assets.current[0].meta.url);
    
    prefetch_file(assets.current[0].meta.url, onSuccess, setAssetLoadedPercent, onError);
    
    // var preloaderInterval = setInterval(() => {
	  //   if(videoRef.current.buffered.length > 0){
		//     var bufferedSeconds = videoRef.current.buffered.end(0) - videoRef.current.buffered.start(0);
		//     let percent = Math.ceil((bufferedSeconds/videoRef.current.duration)*100);
		//     //console.log(bufferedSeconds + ' seconds of video are ready to play!', percent+'%');
		//     setAssetLoadedPercent(percent);
		//     if(percent >= 100){
		// 	    clearInterval(preloaderInterval);
		// 	    setAssetReady(true);
		//     }
	  //   }
    // }, 250);

    
    
/*
    var bufferedSeconds = videoRef.current.buffered.end(0) - videoRef.current.buffered.start(0);
    console.log(bufferedSeconds + ' seconds of video are ready to play!');
*/
    
    //setAssetReady(true)
  }

  const onSuccess = async (url) => {
      console.log(url);
      //clearInterval(preloaderInterval);


      if(videoRef.current.buffered.length > 0){
        var bufferedSeconds = videoRef.current.buffered.end(0) - videoRef.current.buffered.start(0);
        let percent = Math.ceil((bufferedSeconds/videoRef.current.duration)*100);

        console.log('buffered seconds: ', bufferedSeconds)
        console.log('buffered percent: ', percent)

        //console.log(bufferedSeconds + ' seconds of video are ready to play!', percent+'%');
        //setAssetLoadedPercent(percent);
        //if(percent >= 100){
        //  clearInterval(preloaderInterval);
        //  setAssetReady(true);
        //}
      }

      setAssetReady(true);
  }

  const onError = async (xhr) => {
    console.log(xhr);
  }

  const prefetch_file = (url, fetched_callback, progress_callback, error_callback) => {

      //console.log('prefetch_file:url', url);

      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";

      xhr.addEventListener("load", function () {
          if (xhr.status === 200) {
              var URL = window.URL || window.webkitURL;
              var blob_url = URL.createObjectURL(xhr.response);
              fetched_callback(blob_url);
          } else {
              error_callback(xhr);
          }
      }, false);

      var prev_pc = 0;
      xhr.addEventListener("progress", function (event) {
          if (event.lengthComputable) {
              var pc = Math.round((event.loaded / event.total) * 100);
              //console.log('progress', pc);
              if (pc != prev_pc) {
                  prev_pc = pc;
                  progress_callback(pc);
              }
          }
      });
      xhr.send();
  }

  const onAssetEnded = async () => {
    mediaStreams.stop()

    addEvent({
      type: 'section_end',
      sectionName: 'asset_1',
    })

    videoTick()

    handleSaveEvents(events)
    
    onComplete()
  }

  const startSequence = () => {
    sessionStartTime.current = new Date()
    setSeqStarted(true)
    setShowNeutral(true)
    
    addEvent({
      type: 'section_start',
      sectionName: 'neutral_1',
    })

    setTimeout(showAssetSection, 3000)
  }

  const showAssetSection = () => {
    setShowNeutral(false)
    addEvent({
      type: 'section_end',
      sectionName: 'neutral_1',
    })

    setShowAsset(true)
    videoRef.current.play()
  }
  
  if(assets && assets.current && assets.current[0] && (video !== assets.current[0].meta.url)){
	  video = assets.current[0].meta.url;
	  //console.log('assets.current[0].meta.url', assets.current[0].meta.url);
	  //console.log('videoRef', videoRef);
	  //console.log('assets.current[0].meta.url', assets.current[0].meta.url);
  }else{
	  return (null)
  }
  
//   console.log('assets', assets);
//   console.log('video', video);

  return (
    <div className="k-test" data-open={open}>
      <div className="k-neutral" data-open={showNeutral}>
        <img className="k-ripples" src={ripples} alt=""/>
      </div>
      <video
        ref={videoRef} 
        preload="auto"
        data-open={showAsset}
        onPlay={onVideoPlaying}
        onEnded={onAssetEnded}
        onCanPlayThrough={onVideoReady}
        onLoadedMetadata={onVideoMeta}
        onLoadStart={onVideoLoadStart}
        onProgress={onVideoProgress}
        onTimeUpdate={onVideoTimeUpdate}
      >
        <source src={video} type="video/mp4"/>
      </video>
    </div>
  )
}

export default SingleAssetTest