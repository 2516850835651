import './PostTest.css'

import React, { Fragment } from 'react'

import ProgressRing from '../ProgressRing'

const PostTestFinished = (props) => {
  return (
    <Fragment>
      <div><ProgressRing progress={props.uploadProgress}></ProgressRing></div>
      <h2>Analysis in progress.</h2>
      <h3>Please don’t close this window.</h3>
      {/* <p><mark onClick={props.next}>[skip]</mark></p> */}
    </Fragment>
  )
}

export default PostTestFinished
