import './Form.css'
import './AutosuggestTheme.css'
import './DemoForm.css'

import React, { useState, useRef, useEffect } from 'react'
import Autosuggest from 'react-autosuggest';

import Button from '../Button'
import Occupations from '../../data/Occupations'
import Industries from '../../data/Industries'
import EmploymentStatus from '../../data/EmploymentStatus'
import Ages from '../../data/Ages'
import Genders from '../../data/Genders'

const PostTestDemoForm = props => {
  const {
    saveData,
    uploadProgress,
    next
  } = props
  const [activeSection, setActiveSection] = useState(1)
  const [industry, setIndustry] = useState('')
  const [occupation, setOccupation] = useState('')
  const [jobStatus, setJobStatus] = useState('')
  const [age, setAge] = useState('')
  const [gender, setGender] = useState('')
  const [customGender, setCustomGender] = useState('')
  const [address, setAddress] = useState([])

  const formEl = useRef()

  const handleFormSubmit = event => {
    event.preventDefault()
    const data = {
      industry,
      occupation,
      jobStatus,
      age,
      gender: gender === 'custom' ? customGender : gender,
      address
    }
    console.log(data)
    saveData(data)
    
    //if (uploadProgress >= 100) {
      next() // test finished
    //} else {
    //  nextSection() // wait for upload to finish
    //}
  }

  const nextSection = event => {
    if (event) event.preventDefault()
    setActiveSection(prev => prev + 1)
  }

  // if form is finished but upload is still in progress
  // keep track and progress when upload is finished
  useEffect(() => {
    if (uploadProgress >= 100 && activeSection === 3) {
      next()
    }
  }, [uploadProgress, activeSection])

  const miscProps = {
    industry, setIndustry,
    occupation, setOccupation,
    jobStatus, setJobStatus,
    age, setAge,
    gender, setGender,
    customGender, setCustomGender,
    address, setAddress,
    nextSection
  }

	return (
    <div className="k-demo-form">
      {activeSection !== 3 && <h3>Thanks for taking part. We just need a few details about you.</h3>}
      <form
        name="demo"
        className="k-form"
        ref={formEl}
        onSubmit={handleFormSubmit}
      >
        <Part1 open={activeSection === 1} {...miscProps}/>
        <Part2 open={activeSection === 2} {...miscProps}/>
      </form>
      {/*<Part3 open={activeSection === 3}/>*/}
    </div>
	)
}

const Part1 = props => {
  const {
    open,
    industry, setIndustry,
    occupation, setOccupation,
    jobStatus, setJobStatus,
    nextSection
  } = props
  const [canProgress, setCanProgress] = useState(false)
  const placeholder = 'Start typing'

  useEffect(() => {
    if (industry !== '' && occupation !== '' && jobStatus !== '') {
      setCanProgress(true)
    } else {
      setCanProgress(false)
    }
  }, [industry, occupation, jobStatus])

  const jobStatusOptions = EmploymentStatus.map(val => <option key={val} value={val}>{val}</option>)

  return (
    <fieldset data-open={open}>
      <div className="k-input-wrap">
        <label>What industry do you work in?</label>
        <AutoComplete
          id="auto-industry"
          list={Industries}
          placeholder={placeholder}
          value={industry}
          setValue={setIndustry}
        />
      </div>
      <div className="k-input-wrap">
        <label>What's your occupation?</label>
        <AutoComplete
          id="auto-occupation"
          list={Occupations}
          placeholder={placeholder}
          value={occupation}
          setValue={setOccupation}
        />
      </div>
      <div className="k-input-wrap">
        <label>Employment status</label>
        <select name="jobstatus" value={jobStatus} onChange={e => setJobStatus(e.target.value)}>
          <option value="" disabled>Select</option>
          {jobStatusOptions}
        </select>
      </div>
      {canProgress && <p className="k-demo-form__btn-wrap">
        <Button hasArrow handleClick={nextSection}>Next</Button>
      </p>}
    </fieldset>
  )
}

const Part2 = props => {
  const {
    open,
    age, setAge,
    gender, setGender,
    customGender, setCustomGender,
    address, setAddress
  } = props
  const [isGoogleApiInit, setIsGoogleApiInit] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)

  const locationEl = useRef()
  const autocomplete = useRef()

  useEffect(() => {
    if (!window.googleApiReady || isGoogleApiInit) return

    setIsGoogleApiInit(true)

    autocomplete.current = new google.maps.places.Autocomplete(locationEl.current, {types: ['geocode']});

    autocomplete.current.setFields(['address_component']);
    autocomplete.current.addListener('place_changed', onGooglePlaceChanged);

    return () => {
      google.maps.event.clearInstanceListeners(locationEl.current);
    }
  })

  useEffect(() => {
    if (age !== '' && gender !== '' && address.length > 0) {
      setCanSubmit(true)
    }
  }, [age, gender, address])

  const onGooglePlaceChanged = () => {
    setAddress(autocomplete.current.getPlace().address_components)
  }

  const ageOptions = Ages.map(val => <option key={val} value={val}>{val}</option>)
  const genderOptions = Genders.map(val => <option key={val} value={val}>{val}</option>)
  const customGenderOption = <option key="custom" value="custom">I’d like to type my own</option>
  genderOptions.push(customGenderOption)

  return (
    <fieldset data-open={open}>
      <div className="k-input-wrap">
        <label>What is your age?</label>
        <select name="age" value={age} onChange={e => setAge(e.target.value)}>
          <option value="" disabled>Select</option>
          {ageOptions}
        </select>
      </div>
      <div className="k-input-wrap">
        <label>Gender</label>
        <select name="gender" value={gender} onChange={e => setGender(e.target.value)}>
          <option value="" disabled>Select</option>
          {genderOptions}
        </select>
        {gender === 'custom' && <input className="k-form-input-extra" placeholder="Type here" type="text" value={customGender} onChange={e => setCustomGender(e.target.value)}/>}
      </div>
      <div className="k-input-wrap">
        <label>Where do you live?</label>
        <input ref={locationEl} type="text"/>
      </div>
      {canSubmit && <p className="k-demo-form__btn-wrap"><Button isSubmit>Submit</Button></p>}
    </fieldset>
  )
}

const Part3 = props => {
  const { open } = props
  
  return (
    <div className="k-demo-form__finish" data-open={open}>
      <h2>Thanks for participating in this session.</h2>
      <h3>Hold on just a moment longer – we’ll let you know when we’re done.</h3>
    </div>
  )
}

const AutoComplete = props => {
  const { 
    id,
    list,
    placeholder,
    value,
    setValue
  } = props
  const [suggestions, setSuggestions] = useState([])

  const generateSuggestions = ({value}) => {
    const newSuggestions = list.filter(item => {
      return item.toLowerCase().includes(value.toLowerCase())
    })
    setSuggestions(newSuggestions)
  }

  const handleInputChange = (event, {newValue}) => {
    setValue(newValue)
  }

  return (
    <Autosuggest
      id={id}
      suggestions={suggestions}
      onSuggestionsFetchRequested={generateSuggestions}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={val => val}
      renderSuggestion={val => val}
      inputProps={{
        placeholder,
        value,
        onChange: handleInputChange
      }}
      // alwaysRenderSuggestions={true}
    />
  )
}

export default PostTestDemoForm