import './PreTestCamera.css'

import React, { Fragment, useEffect, useRef, useState } from 'react'

import Button from '../Button'
import { useMediaStreams } from '../MediaStreamRecorder'

const PreTestStart = (props) => {
  const { next, assetReady, assetLoadedPercent } = props;
  
  let _assetLoadedPercent = 0;
  
  const videoRef = useRef()
  
  const [hasStream, setHasStream] = useState(false)

  const btnLabel = assetReady ? 'Let\'s begin' : 'Preparing asset'
  
  useEffect(() => {
    if (hasStream) return;

    async function connectVideo () {
      if (navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true })
          videoRef.current.srcObject = stream
          setHasStream(true);
        } catch (err) {
          console.log("Something went wrong!", err)
        }
      }
    }

    connectVideo()
  })

  const handleClick = () => {
    if (assetReady) next()
  }
  
  if(!assetReady){
	  return (
	    <Fragment>
	    <div className="pretest-panel">
	      <h2>Sit comfortably.<br/>Position your face inside the frame.</h2>
	      <div className="k-cam-wrap">
	        <video className="k-cam k-cam--big" autoPlay ref={videoRef}/>
	        <div className="k-cam-zone"></div>
	      </div>
	      <p>This session will last less than three minutes.</p>
	      <p>Please sit comfortably, put down that coffee and simply watch the screen. Easy.</p>
		  <p>Your study is almost ready...<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> {assetLoadedPercent}%</p>
	    </div>
	    </Fragment>
	  )
  }else{
	  return (
	    <Fragment>
	    <div className="pretest-panel">
	      <h2>Sit comfortably.<br/>Position your face inside the frame.</h2>
	      <div className="k-cam-wrap">
	        <video className="k-cam k-cam--big" autoPlay ref={videoRef}/>
	        <div className="k-cam-zone"></div>
	      </div>
	      <p>This session will last less than three minutes.</p>
	      <p>Please sit comfortably, put down that coffee and simply watch the screen. Easy.</p>
	      <p><Button loading={!assetReady} handleClick={handleClick}>{btnLabel}</Button></p>
	    </div>
	    </Fragment>
	  )
  }
}

export default PreTestStart
