import './MobileWarning.css'

import React from 'react'

const MobileWarning = props => {
	
	const { 
	    mobile_message
	} = props;
	
	return (
		<div className="mobile-warning">
			<div>
				<h2>{mobile_message.heading}</h2>
				<h3>{mobile_message.subheading}</h3>
				<br/><br/><br/><br/><br/><br/>
				<p className="mobile-warning__try">{mobile_message.message}</p>
			</div>
		</div>
	)
}

export default MobileWarning