import './Form.css'

import React, { Fragment, useState } from 'react'

import Button from '../Button'

class PostTestSurvey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      optIn: '',
      saving: false,
      isDemo: true,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOptOut = this.handleOptOut.bind(this);
    this.handleOptInChange = this.handleOptInChange.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleFormSubmit = (event) => {
    event.preventDefault()

    if (this.state.saving) return;
    
    this.setState({saving: true})
    
    this.props.saveData(this.state).then(() => {
      this.props.next()
    })
  }

  handleOptOut(event) {
    event.preventDefault()
    this.props.next()
  }

  handleOptInChange(event) {
    this.handleChange(event)
    this.props.setOptInReport(!this.props.optInReport)
  }

  render() {
    const { saving } = this.state;

    return (
      <Fragment>
          <h2>Ta da. All done.</h2>
          <h3>Where should we send your emotional score report?</h3>
          <form name="survey" onSubmit={this.handleFormSubmit} className="k-form k-report-form">
            <div className="k-form-split">
              <div className="k-input-wrap">
                  <label>First name</label>
                  <input name="firstName" onChange={this.handleChange} type="text" required />
              </div>
              <div className="k-input-wrap">
                  <label>Last name</label>
                  <input name="lastName" onChange={this.handleChange} type="text" required />
              </div>
            </div>
            <div className="k-input-wrap">
              <label>Email address</label>
              <input name="email" onChange={this.handleChange} type="email" required />
            </div>
            {/*<div className="k-input-wrap">
              <label className="k-label-check"><input name="optIn" type="checkbox" checked={this.props.optInReport} onChange={this.handleOptInChange}/> I’d like to receive updates from Kanjo – keep up to date with the latest news, insights, data and awesome features Kanjo has to offer me.</label>
            </div>*/}
            <div className="k-submit-wrap">
              <Button isSubmit loading={saving}>Send me my report</Button>
              <br/>
              <a href="#" onClick={this.handleOptOut}>Don't send me my report</a>
            </div>
          </form>
      </Fragment>
    )
  }
}

export default PostTestSurvey

// Old fields
/*
<TextField
  className={classes.field}
  fullWidth
  label="Your name"
  required
  variant="outlined"
/>
<TextField
  className={classes.field}
  label="Your gender"
  required
  select
  SelectProps={{
    style: {
      width: 192
    },
  }}
  variant="outlined"
>
  <MenuItem value="female">Female</MenuItem>
  <MenuItem value="male">Male</MenuItem>
  <MenuItem value="non-binary">Non-binary or third gender</MenuItem>
  <MenuItem value="transgender-female">Transgender female</MenuItem>
  <MenuItem value="transgender-male">Transgender male</MenuItem>
  <MenuItem value="not-listed">Not listed</MenuItem>
  <MenuItem value="prefer-not-to-say">Prefer not to say</MenuItem>
</TextField>
<TextField
  className={classes.field}
  label="Your age"
  required
  select
  SelectProps={{
    style: {
      width: 128
    },
  }}
  variant="outlined"
>
  <MenuItem value="11-17">11-17</MenuItem>
  <MenuItem value="18-19">18-19</MenuItem>
  <MenuItem value="20-24">20-24</MenuItem>
  <MenuItem value="25-29">25-29</MenuItem>
  <MenuItem value="30-39">30-39</MenuItem>
  <MenuItem value="40-49">40-49</MenuItem>
  <MenuItem value="50-59">50-59</MenuItem>
  <MenuItem value="60-69">60-69</MenuItem>
  <MenuItem value="70-79">70-79</MenuItem>
  <MenuItem value="80+">80+</MenuItem>
</TextField>
*/