import './ProcessingCover.css'

import React from 'react'

const ProcessingCover = props => {
	return (
		<div className="k-processing-cover">
			<div className="k-processing-cover__spinner"></div>
		</div>
	)
}

export default ProcessingCover