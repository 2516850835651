import './Checkbox.css'
import checkIcon from './check.svg'

import React from 'react'

const Checkbox = props => {
  const {
    children: label,
    checked = false,
    setChecked
  } = props

	return (
    <label className="k-checkbox">
      <input
        className="k-checkbox__check"
        type="checkbox"
        checked={checked}
        onChange={e => setChecked(e.target.checked)}
      />
      <span className="k-checkbox__icon">
        <img src={checkIcon} alt=""/>
      </span>
      {label}
    </label>
  )
}

export default Checkbox