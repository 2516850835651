import './NotChrome.css'

import React from 'react'

const NotChrome = props => {
	
	const { 
	    input_data
	} = props;

	const copyUrlToClipboard = (event) => {
		event.preventDefault();
		var copyText = document.getElementById("link_url");
	  	copyText.select(); 
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");
		copyText.setSelectionRange(0, 0);
		copyText.blur();

		var copy_button = document.getElementById("copy_button");
		copy_button.innerHTML = 'Copied!';
		setTimeout(() => {
			copy_button.innerHTML = 'Copy';
		}, 3000);
	}
	
	return (
		<div className="mobile-warning">
			<div>
				<h2>{input_data.heading}</h2>
				<h3>{input_data.subheading}</h3>
				<div class="nochrome_warning_inner">
					<p className="mobile-warning__try" dangerouslySetInnerHTML={{__html:input_data.message}}></p>
					<div class="copy_input_wrap">
						<input id="link_url" type="text" readonly value={window.location.href} />
						<a href="#" id="copy_button" onClick={copyUrlToClipboard}>Copy</a>
					</div>
					<p className="mobile-warning__download" dangerouslySetInnerHTML={{__html:input_data.strapline}}></p>
				</div>
			</div>
		</div>
	)
}

export default NotChrome