const pushEvent = event => {
  if (typeof window.dataLayer === 'undefined') return
  
  window.dataLayer.push(event)
}

const trackScreen = screenName => {
  console.info('Track Screen: ', screenName)
  pushEvent({
    event: 'screenShown',
    screenName
  })
}

const GTM = {
  pushEvent,
  trackScreen
}

export default GTM