import React, { createContext, useContext, useState } from 'react'

const MediaStreamContext = createContext()
MediaStreamContext.displayName = 'MediaStreamContext'

const recordMedia = (state, recordingCallback) => {
  const stream = new MediaStream()
  if (state.camera) {
    state.camera.getVideoTracks().forEach(t => stream.addTrack(t))
  }
  if (state.display) {
    state.display.getVideoTracks().forEach(t => stream.addTrack(t))
  }
  if (state.microphone) {
    state.microphone.getAudioTracks().forEach(t => stream.addTrack(t))
  }
  const mediaRecorder = new MediaRecorder(stream)
  const chunks = []
  mediaRecorder.ondataavailable = e => {
    chunks.push(e.data)
    // console.log(state.chunks.length)
    // TODO: ideally chunks should be periodically flushed to disk
    // TODO: stream to AWS here?
  }
  mediaRecorder.onerror = event => {
    let error = event.error

    switch(error.name) {
      case 'InvalidStateError':
        console.log('You cannot record the video right now. Try again later.')
        break;
      case 'SecurityError':
        console.log('Recording the specified source is not allowed due to security restrictions.')
        break;
      default:
        console.log('A problem occurred while trying to record the video.')
        break;
    }
  }
  mediaRecorder.onstop = e => {
    recordingCallback(e, {
      blob: new Blob(chunks, {type: 'video/x-matroska;codecs=avc1'}),
      type: 'video/x-matroska;codecs=avc1'
    })
  }
  // the parameter determines how often the ondataavailable is called
  mediaRecorder.start(5000)

  return mediaRecorder
}

function MediaStreamRecorder(props) {
  const [state, setState] = useState({
    camera: null,
    display: null,
    microphone: null
  })

  const [mediaRecorder, setMediaRecorder] = useState(null)

  const record = React.useCallback(callback => {
    setMediaRecorder(recordMedia(state, callback))
  }, [state])

  const setup = React.useCallback((type, stream) => {
    state[type] = stream
    setState(state)
  }, [state])

  const stop = React.useCallback(() => {
    mediaRecorder.stop()
  }, [mediaRecorder])

  const value = React.useMemo(() => ({ mediaRecorder, record, setup, stop, streams: state }), [
    mediaRecorder,
    record,
    setup,
    state,
    stop
  ])

  return <MediaStreamContext.Provider value={value} {...props} />
}

function useMediaStreams() {
  const context = useContext(MediaStreamContext)
  if (context === undefined) {
    throw new Error(`useMediaStreams must be used within a MediaStreamRecorder`)
  }
  return context
}


export { MediaStreamRecorder, useMediaStreams }
