import React, { Fragment, useState } from 'react'

import Button from '../Button'
import Terms from '../Terms'
import Checkbox from '../Checkbox'

const PreTestIntro = (props) => {
  const [termsOpen, setTermsOpen] = useState(false)
  const [privacyOpen, setPrivacyOpen] = useState(false)
  const [cookiesOpen, setCookiesOpen] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const openTerms = event => {
    event.preventDefault()
    setTermsOpen(true)
  }

  const openPrivacyPolicy = event => {
    event.preventDefault()
    setPrivacyOpen(true)
  }

  const openCookiesPolicy = event => {
    event.preventDefault()
    setCookiesOpen(true)
  }

  const closeTermsPrivacy = () => {
    setTermsOpen(false)
    setPrivacyOpen(false)
    setCookiesOpen(false)
  }
  
  if(process.env.REACT_APP_INSTANCE == 'demo' || process.env.REACT_APP_INSTANCE == 'demo_nspcc'){
	  
	  
	  return (
	    <Fragment>
	    <div className="pretest-panel">
	      <h2>Let’s get going.</h2>
	      <h3>Please don’t record, share or talk about what you’re about to see.</h3>
	      
	      <p className="k-start-agree"><Checkbox checked={agreed} setChecked={setAgreed}>Yes that's OK with me</Checkbox></p>
	
	      {agreed && <p><Button handleClick={props.next}>Get started</Button></p>}
	      
	      <p className="k-terms-txt">By participating in this test you agree to our <a href="#" onClick={openTerms}>terms</a>, <a href="#" onClick={openPrivacyPolicy}>privacy policy</a> and <a href="#" onClick={openCookiesPolicy}>cookie policy.</a></p>
	      
	      <Terms close privacyPolicy cookiesPolicy preloadBackgroundOnly></Terms>
	      {termsOpen && <Terms close={closeTermsPrivacy}></Terms>}
	      {privacyOpen && <Terms close={closeTermsPrivacy} privacyPolicy></Terms>}
	      {cookiesOpen && <Terms close={closeTermsPrivacy} privacyPolicy cookiesPolicy></Terms>}
	    </div>
	    </Fragment>
	  )
	  
	  
  }else{

	  return (
	    <Fragment>
	    <div className="pretest-panel">
	      <h2>Let’s get going.</h2>
	      <h3>You’re a member of an exclusive research panel.<br/>Please don’t record, share or talk about what you’re about to see.</h3>
	      
	      <p className="k-start-agree"><Checkbox checked={agreed} setChecked={setAgreed}>Yes that's OK with me</Checkbox></p>
	
	      {agreed && <p><Button handleClick={props.next}>Get started</Button></p>}
	      
	      <p className="k-terms-txt">By participating in this test you agree to our <a href="#" onClick={openTerms}>terms</a>, <a href="#" onClick={openPrivacyPolicy}>privacy policy</a> and <a href="#" onClick={openCookiesPolicy}>cookie policy.</a></p>
	      
	      <Terms close privacyPolicy cookiesPolicy preloadBackgroundOnly></Terms>
	      {termsOpen && <Terms close={closeTermsPrivacy}></Terms>}
	      {privacyOpen && <Terms close={closeTermsPrivacy} privacyPolicy></Terms>}
	      {cookiesOpen && <Terms close={closeTermsPrivacy} privacyPolicy cookiesPolicy></Terms>}
	    </div>
	    </Fragment>
	  )
  
  }
}

export default PreTestIntro
