import './UploadHeader.css'
import { useBeforeunload } from 'react-beforeunload';

import React from 'react'

import ProgressRing from '../ProgressRing'

const UploadHeader = props => {
  const { uploadProgress } = props

  if(uploadProgress < 100){
    useBeforeunload(() => 'Analysis in progress. Closing this window will result in an incomplete session.');
  }

  return (
    <div className="upload-header">
        <ProgressRing color="#9FC55A" progress={uploadProgress}/>
        <h2>Please don’t close this window</h2>
        <h3>Analysis in progress. Closing this window will result in an incomplete session.</h3>
    </div>
  )
}

export default UploadHeader