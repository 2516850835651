import './TestBlocked.css'

import React from 'react'

const TestBlocked = props => {
	
	const { 
	    blocked_message
	} = props;
	
	return (
    <div className="k-test-blocked">
      <div>
        <h2>{blocked_message.heading}</h2>
        <h3>{blocked_message.message}</h3>
      </div>
    </div>
  )
}

export default TestBlocked