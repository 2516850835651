let industries = [
	'Accountancy, banking and finance',
	'Animal, agriculture and environment',
	'Business, consulting and management',
	'Charity and voluntary work',
	'Childcare, education and training',
	'Creative arts and design',
	'Energy and utilities',
	'Engineering and manufacturing',
	'Environment and agriculture',
	'Hair, beauty and wellbeing',
	'Health',
	'Hospitality and events management',
	'Finance',
	'Information technology',
	'Law',
	'Law enforcement and security',
	'Marine and maritime',
	'Marketing, advertising and PR',
	'Media and internet',
	'Property and construction',
	'Public services and administration',
	'Recruitment and HR',
	'Retail and customer services',
	'Sales',
	'Science and pharmaceuticals',
	'Security and emergency services',
	'Social care',
	'Sports, leisure and tourism',
	'Teacher training and education',
	'Transport, logistics and distribution'
]

export default industries.sort()