import './Button.css'

import React from 'react'

const Button = (props) => {
  const Arrow = <svg xmlns="http://www.w3.org/2000/svg" width="21.156" height="16" viewBox="0 0 21.156 16"><path d="M86.441,89.373l-8.3-7.349a.8.8,0,0,0-1.039,0,.6.6,0,0,0,0,.92l7.043,6.238H66.235a.656.656,0,1,0,0,1.3H84.148l-7.043,6.238a.6.6,0,0,0,0,.92.8.8,0,0,0,1.039,0l8.3-7.349a.6.6,0,0,0,0-.92Z" transform="translate(-65.5 -81.833)" fill="#fff"/></svg>

  const btnProps = {
    className: `btn btn--${props.colour} btn--${props.size}`
  }

  if (props.isSubmit) btnProps.type = 'submit'
  
  if (props.handleClick) btnProps.onClick = props.handleClick
  
  if (props.loading) btnProps.className += ' btn--loading'

  return <button {...btnProps}>{props.children} {props.hasArrow && Arrow}</button>
}

export default Button