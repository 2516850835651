import './Logo.css'

import React from 'react'

const Logo = props => {
  return <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="118.24" height="50" viewBox="0 0 118.24 50">
    <path data-name="Path 17" d="M175.564,120.209a22.985,22.985,0,0,1,6.6,1.166c0-5.658-.721-8.542-5.768-8.542a18.75,18.75,0,0,0-7.654,1.72c-.554.221-.887,0-1-.389l-.277-.776c-.278-.5.055-.777.332-.943a18.9,18.9,0,0,1,8.819-1.887c7.765,0,7.987,4.548,7.987,11.148v14.2a.9.9,0,0,1-.888.832h-.444a.711.711,0,0,1-.776-.721l-.277-2.33a13.273,13.273,0,0,1-8.985,3.661c-4.771,0-8.32-3.217-8.32-8.487C164.915,123.926,168.853,120.209,175.564,120.209Zm-2.052,14.753a12.2,12.2,0,0,0,8.652-3.882V123.7a17.624,17.624,0,0,0-6.6-1.33c-4.88,0-8.264,2.662-8.264,6.433A5.956,5.956,0,0,0,173.511,134.963Z" transform="translate(-140.633 -100.489)" fill="#465363"/>
    <path data-name="Path 18" d="M192.542,111.947a.832.832,0,0,1,.888-.832h.277a.674.674,0,0,1,.776.666l.278,2.718a12.458,12.458,0,0,1,9.04-3.939c7.266,0,9.65,4.493,9.65,11.2v14.143a.852.852,0,0,1-.832.832h-.775a.852.852,0,0,1-.832-.832V121.874c0-5.934-2.386-8.93-7.321-8.93a11.681,11.681,0,0,0-8.708,4.381v18.58a.736.736,0,0,1-.832.832h-.888c-.5,0-.721-.388-.721-.832Z" transform="translate(-139.477 -100.489)" fill="#465363"/>
    <path data-name="Path 19" d="M223.951,141.081c0,5.934-2.274,8.32-6.434,9.706-.665.221-1.053.111-1.275-.444l-.222-.554a.884.884,0,0,1,.554-1.221c2.94-.72,4.937-2.551,4.937-6.988V112.351a.817.817,0,0,1,.831-.832h.776a.9.9,0,0,1,.832.832Zm-3-38.459a1.741,1.741,0,0,1,1.773-1.728,1.707,1.707,0,1,1,0,3.414A1.733,1.733,0,0,1,220.953,102.621Z" transform="translate(-138.498 -100.893)" fill="#465363"/>
    <path data-name="Path 20" d="M243.268,110.559a13.118,13.118,0,0,1,12.867,13.311c0,7.488-5.711,13.478-12.867,13.478-7.321,0-12.868-5.991-12.868-13.478A13.012,13.012,0,0,1,243.268,110.559Zm0,24.4c5.712,0,10.26-4.936,10.26-11.092,0-5.989-4.548-10.926-10.26-10.926a10.671,10.671,0,0,0-10.427,10.926C232.841,130.027,237.444,134.963,243.268,134.963Z" transform="translate(-137.894 -100.489)" fill="#465363"/>
    <path data-name="Path 21" d="M160.343,135.353l-10.2-13.791,8.183-8.242a.651.651,0,0,0,.071-.98l-.559-.561a.657.657,0,0,0-1.051,0s-10.954,11.074-12.63,12.86l-.107.114,0-22.972c0-.445-.222-.832-.721-.832h-.888a.736.736,0,0,0-.832.832v34.572c0,.445.222.832.721.832h.888a.736.736,0,0,0,.832-.832l0-9.06,4.335-4.177,7.624,10.438,2.36,3.234a.73.73,0,0,0,1.073.248l.717-.522A.736.736,0,0,0,160.343,135.353Z" transform="translate(-141.608 -100.891)" fill="#465363"/>
  </svg>
}

export default Logo