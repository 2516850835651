import './PreTest.css'

import React, { useEffect, useRef, useState } from 'react'

import PreTestCamera from './PreTestCamera'
import PreTestDialogIntro from './PreTestIntro'
// import PreTestDialogMicrophone from './PreTestDialogMicrophone'
import PreTestDisplay from './PreTestDisplay'
import PreTestStart from './PreTestStart'

import GTM from '../../lib/gtm'

const steps = {
  intro: {
    name: 'intro',
    component: PreTestDialogIntro,
    nextStep: 'camera'
  },
  // microphone: {
  //   component: PreTestDialogMicrophone,
  //   nextStep: 'camera'
  // },
  camera: {
    name: 'camera',
    component: PreTestCamera,
    nextStep: 'display'
  },
  display: {
    name: 'display',
    component: PreTestDisplay,
    nextStep: 'start'
  },
  start: {
    name: 'start',
    component: PreTestStart
  }
}

const PreTest = (props) => {
  const {
    onComplete,
    open,
    assetReady,
    assetLoadedPercent
  } = props
  const prevOpen = useRef()
  const [step, setStep] = useState(steps.intro)

  useEffect(() => {
    GTM.trackScreen('pretest ' + step.name)
  }, [step])

  useEffect(() => {
    if (open && !prevOpen.current) {
      setStep(steps.intro)
    }
    prevOpen.current = open
  }, [open])

  const handleNext = () => {
    if (step.nextStep != null) {
      setStep(steps[step.nextStep])
    } else if (onComplete) {
      onComplete()
    }
  }

  return (
      <div className="k-pre-test">
        <div>
          <step.component assetReady={assetReady} next={handleNext} assetLoadedPercent={assetLoadedPercent}/>
        </div>
      </div>
  )
}

export default PreTest
